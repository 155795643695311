<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card style="min-width: 1000px">
      <el-row>
        <el-col class="">
          <div class="">
            <el-button type="primary" size="medium" class="demo-input-label" style="width: 100px" icon="el-icon-plus" @click="addButtonClick('1', {})">添加</el-button>
          </div>
        </el-col>
      </el-row>
      <el-table :data="dictList" style="width: 100%" row-key="id" border lazy load :tree-props="{ children: 'children' }">
        <el-table-column prop="name" label="名称" sortable width="180"></el-table-column>
        <el-table-column prop="code" label="code" sortable width="130"></el-table-column>
        <el-table-column prop="dictNo" label="dictNo" width="100"></el-table-column>
        <el-table-column prop="updateTime" label="updateTime"></el-table-column>
        <el-table-column label="排序" width="180" sortable>
          <template #default="scope">
            <el-tag size="mini" v-if="scope.row.level === 1">一级</el-tag>
            <el-tag type="success" size="mini" v-else-if="scope.row.level === 2">二级</el-tag>
            <el-tag type="warning" size="mini" v-else>三级</el-tag>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="200">
          <template #default="scope">
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="addButtonClick('2', scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" icon="el-icon-delete" @click="deleteDict(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 添加分类的对话框 -->
    <el-dialog title="外层 Dialog" :visible.sync="addDialog">
      <el-form :model="addTagFromParams" ref="updateDictRef" :rules="updateRules" label-width="100px" class="demo-ruleForm">
        <el-form-item label="标签名称" prop="name">
          <el-input v-model="addTagFromParams.name" placeholder="请输入标签名称"></el-input>
        </el-form-item>
        <el-form-item label="父级标签" prop="parentIds">
          <el-cascader
            ref="demoCascader"
            v-model="addTagFromParams.parentIds"
            show-all-levels="false"
            :options="cascaderDictList"
            :props="{ checkStrictly: true, label: 'name', value: 'id' }"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="标签code" prop="code">
          <el-input v-model="addTagFromParams.code" type="string" placeholder="请输入标签code"></el-input>
        </el-form-item>
        <el-form-item label="标签层级">
          <el-input v-model="addTagFromParams.parentIds.length" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="addTagFromParams.sort" onkeyup="value=value.replace(/[^\d]/g,'')" placeholder="请输入数字"></el-input>
        </el-form-item>
        <el-form-item label="dictNo" prop="dictNo">
          <el-input v-model="addTagFromParams.dictNo" onkeyup="value=value.replace(/[^\d]/g,'')" placeholder="请输入数字100-1000"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm()">提交</el-button>
          <el-button @click="addDialog = false">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
const addParams = {
  name: '',
  parentId: '0',
  parentIds: ['0'],
  code: '',
  level: '1',
  sort: 1,
  status: '0',
  type: 1,
  dictNo: '',
  description: '123',
}
export default {
  name: '后台项目Index',

  data() {
    return {
      addDialog: false,
      dictList: [],
      cascaderDictList: [
        {
          id: '0',
          name: '基础',
          parentId: '0',
          parentIds: ['0', '100'],
          children: [],
        },
      ],
      addTagFromParams: { ...addParams },
      updateTagType: 1, //1为添加2为更新
      updateRules: {
        name: [
          { required: true, message: '请输入标签名称', trigger: 'blur' },
          {
            min: 2,
            max: 15,
            message: '长度在 2 到 15 个字符',
            trigger: 'blur',
          },
        ],
        code: [
          { required: true, message: '请输入标签code', trigger: 'blur' },
          {
            min: 2,
            max: 30,
            message: '长度在 2 到 30 个字符',
            trigger: 'blur',
          },
        ],
        sort: [{ required: true, message: '请输入排序等级', trigger: 'blur' }],
        parentIds: [{ required: true, message: '请选择父级标签', trigger: 'blur' }],
        dictNo: [{ required: true, message: '请输入dictNo', trigger: 'blur' }],
      },
    }
  },

  mounted() {
    this.getTagsList()
  },

  methods: {
    async getTagsList() {
      const res = await this.$API.dict.get({ dictType: 1 })
      if (res.status !== 200) return this.$message.error(res.msg)
      this.dictList = res.data
      this.cascaderDictList[0].children = this.dictList
    },

    //点击编辑
    async addButtonClick(type, item) {
      this.addDialog = true
      this.updateTagType = type
      if (type == 1) {
        return (this.addTagFromParams = { ...addParams })
      }
      let params = { ...item }
      params.parentIds = JSON.parse(params.parentIds).map((res) => res + '')

      console.log(params.parentIds)
      this.addTagFromParams = { ...params }
    },

    async submitForm() {
      this.$refs.updateDictRef.validate(async (valid) => {
        if (!valid) return
        this.addAndUpdataDict()
      })
    },
    async addAndUpdataDict() {
      this.$refs['updateDictRef'].validate(async (valid) => {
        if (valid) {
          let params = this.addTagFromParams
          params.parentId = params.parentIds[params.parentIds.length - 1]
          params.level = params.parentIds.length.toString()
          params.parentIds = JSON.stringify(params.parentIds.map((res) => +res))
          const res = await this.$API.dict.add(this.addTagFromParams)
          if (res.status !== 200) return this.$message.error(res.msg)
          this.$message.success(res.msg)
          this.getTagsList()
          this.addDialog = false
        } else {
          return this.$message.error('必填项不能为空')
        }
      })
    },

    //删除
    async deleteDict(dictId) {
      const confirmResult = await this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).catch(async (err) => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const res = await this.$API.dict.delete({ id: dictId })
      if (res.status !== 200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.getTagsList()
    },
  },
}
</script>

<style lang="scss" scoped></style>
